<template>
  <div>
    <div class="page-wrapper chiller-theme" style="margin-top: 71px">
      <main class="page-content">
        <div class="container-fluid">
          <ul
            class="breadcrumb ProximaNovaRegular"
            style="margin-left: 52px; margin-top: 12px"
          >
            <li>
              <span>Solutions</span>
            </li>
            <li>
              <span>Agile</span>
            </li>
            <li class="ProximaNovaBold">Marketing channel form</li>
          </ul>
        </div>
        <div class="container mb-5" style="margin-top: 28px">
          <div
            class="
              col-sm-12 col-xs-12 col-md-12 col-lg-12
              d-flex
              justify-center
            "
          >
            <div class="col-sm-12 col-md-7 col-xs-12 col-lg-7">
              <w-multi-step-bar
                :stepName="stepName"
                :activeState="activeFrom"
              ></w-multi-step-bar>
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          style="margin-top: 80px; padding-left: 52px"
          v-if="activeFrom === 1"
        >
          <w-tab
            :tabName="activeTab"
            :tabs="tabs"
            @activeTab="changeTab($event)"
          >
          </w-tab>
        </div>
        <div class="w-100" v-if="activeFrom === 1">
          <div
            class="container-fluid tab-panel"
            style="margin-top: 36px; padding-left: 52px"
            v-for="(tab, index) in tabs"
            :key="index"
            :class="{ showConnectors: activeTab === tab.category }"
          >
            <div
              class="card w-100"
              v-for="(tabContent, index) in tab.tabContent"
              :key="index"
            >
              <div
                class="card-header font-large ProximaNovaBold"
                style="
                padding: 0.75rem 1.5rem;
                background: transparent;
                border: 0px !important;
              "
              >
                {{ tabContent.title }}
                <span
                  class="float-right view-all-link"
                  v-if="tabContent.connectors.length >= 8"
                  @click="viewAll"
                  >View All</span
                >
              </div>
              <div class="card-body">
                <div>
                  <w-connector
                    :addActiveClass="false"
                    :connectors="tabContent.connectors"
                    :title="tabContent.title"
                    :selectedConnectorsList="selectedConnectors"
                    @addPlatform="addPlatform($event)"
                  >
                  </w-connector>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="container-fluid"
          style="margin-top: 80px; padding-left: 52px"
          v-if="activeFrom === 2"
        >
          <w-tab
            :tabName="activeTab"
            :tabs="categorizedSelectedConnectors"
            @activeTab="changeTab($event)"
          >
          </w-tab>
        </div>
        <div class="w-100" v-if="activeFrom === 2">
          <div
            class="container-fluid tab-panel"
            style="margin-top: 36px; padding-left: 52px"
            v-for="(tab, index) in categorizedSelectedConnectors"
            :key="index"
            :class="{ showConnectors: activeTab === tab.category }"
          >
            <div
              class="card w-100"
              v-for="(tabContent, index) in tab.tabContent"
              :key="index"
            >
              <div
                class="card-header font-large ProximaNovaBold"
                style="
                padding: 0.75rem 1.5rem;
                background: transparent;
                border: 0px !important;
              "
              >
                {{ tabContent.title }}
              </div>
              <div class="card-body">
                <div>
                  <w-connector
                    :addActiveClass="true"
                    :connectors="tabContent.connectors"
                    :title="tabContent.title"
                    :selectedConnectorsList="selectedConnectors"
                  >
                  </w-connector>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 py-4" v-if="activeFrom === 3">
          <div
            class="container-fluid"
            style="margin-top: 36px; padding-left: 52px;"
          >
            <div class="col-md-12 col-lg-12">
              <div class="card w-100 kpi-box">
                <div class="row">
                  <div class="col-md-4 colo-lg-4">
                    <div class="w-100 img-section">
                      <img
                        class="img img-responsive"
                        src="../../assets/Icons/group-21.png"
                        alt="KPI"
                        height="100%"
                      />
                    </div>
                  </div>
                  <div class="col-md-8 col-lg-8">
                    <div class="w-100 row " style="height: 100%">
                      <div
                        class="w-100 d-flex px-5"
                        style="height: 35%; justify-content: start; align-items: flex-end;"
                      >
                        <span
                          class="ProximaNovaBold"
                          style="font-size: x-large;"
                          >Define KPI</span
                        >
                      </div>
                      <div
                        class="col-md-6 colo-lg-6 d-flex justify-center"
                        style="height: 40%"
                      >
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown
                            :options="kpidropdown"
                            :placeHolder="'Select'"
                            :labelText="'KPI'"
                            class="mr-3"
                            @input="selectedKPi($event)"
                          ></w-dropdown>
                        </div>
                      </div>
                      <div
                        class="col-md-6 colo-lg-6 d-flex justify-center"
                        style="height: 40%"
                      >
                        <div class="w-100 p-4 pr-5">
                          <w-dropdown
                            :options="marketDropdown"
                            :labelText="'Market Type'"
                            class="mr-5"
                            @input="selectMarket($event)"
                          ></w-dropdown>
                        </div>
                      </div>
                      <div class="w-100 pr-5" style="height: 25%">
                        <div
                          class="row mr-2 pr-2"
                          style="float: right;"
                          :class="{ changeColor: isDisable }"
                        >
                          <w-button
                            :buttonLoader="'normal'"
                            :buttonText="'Create Plan'"
                            :class="{ resetPasswordLink: isDisable }"
                            :isDisable="isDisable"
                            @buttonClicked="createPlan"
                          >
                          </w-button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid w-100" v-if="activeFrom < 3">
          <div class="row pt-5 pb-5 mr-2" style="float: right;">
            <w-button
              :buttonLoader="'normal'"
              :buttonText="'Continue'"
              :isDisable="disableConnectors"
              :class="{ resetPasswordLink: disableConnectors }"
              @buttonClicked="nextToSource"
            >
            </w-button>
          </div>
        </div>
       
        <modal
        name="newWelcomePage"
        :width="388"
        class="welcome--modal"
        @closed="hideWelcomePage"
        :clickToClose="false"
        v-show="isStartNewModal"
      >
        <div class="">
          <div class="w-100 modal-scroll">
            <div class="row modal-padding">
              <div class="col p-0 welcome ml-4 mt-4 align-start">
                <img src="../../assets/Icons/group-21.png" alt class="welcome--image" />
              </div>
              <div class="col p-0 intro__content">
                
        
                <p class="Welcome-to-Data-Poem ProximaNovaRegular">
                  Digital Media
                </p>
                <div class="input-group">
                  <div class="form-outline">
                    <input type="search" id="form1" class="form-control search-box ProximaNovaBold" v-model="searchTerm" v-on:keyup="resultQuery" placeholder="Search"/>
                  </div>
                  <button type="button" class="btn btn-primary search-button">
                    <i class="fas fa-search fa-2x"></i>
                  </button>                  
                </div>
                <div class="modal-body">
                  <div
                    class="container-fluid"
                    style="margin-top: 36px;padding:0px"
                  >
                    <div
                      class="card1 w-100"
                    >
                      <div class="card-body1">
                        <div class="modal-connectors">
                          <w-connector
                            :addActiveClass="false"
                            :connectors="connectors"
                            :selectedConnectorsList="selectedConnectors"
                            @addPlatform="addPlatform($event)"
                          >
                          </w-connector>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row w-100 cancel-btn-section">
                  <div class="col-sm-6 col-md-3 offset-md-3 text-right d-flex justify-content-end">
                    <span class="start-from-fresh mt-4" @click="hideWelcomePage">Cancel</span>
                  </div>
                  <div class="col-sm-6 col-md-6 cancel-btn-section">
                    <button
                      color="primary isi"
                      class="btn-effect-intro-modal"
                      size="button--size-m"
                      @click="hideWelcomePage"
                    >
                      <span class="btn-text">Done </span>
                      <i
                        class="fas fa-arrow-right"
                        style="align-items: center"
                      ></i>
                      
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      </main>
    </div>
  </div>
</template>
<script>
import Connector from "@/components/AIMO-AI/Connector.vue";
import MultiStepProgressBar from "@/components/Solutions/MultiStepProgressBar.vue";
import Button from "@/components/Profile/Button.vue";
import Tab from "@/components/Solutions/Tab.vue";
import Dropdown from "@/widgets/Dropdown.vue";
export default {
  props: [],
  components: {
    "w-connector": Connector,
    "w-multi-step-bar": MultiStepProgressBar,
    "w-button": Button,
    "w-tab": Tab,
    "w-dropdown": Dropdown,
  },
  data() {
    return {
      searchTerm: "",
      isShow:false,
      kpidropdown: [
        { text: "Sales", id: "sales" },
        { text: "Leads", id: "leads", disable: true },
        { text: "Profit Margin", id: "Profit Margin", disable: true },
        { text: "Volume", id: "volume", disable: true },
        { text: "Cart Value", id: "Cart Value", disable: true },
      ],
      marketDropdown: [
        { text: "All India", id: "allindia" },
        { text: "USA", id: "USA" },
        { text: "HSM", id: "hsm", disable: true },
        { text: "KER", id: "ker", disable: true },
        { text: "KA", id: "KA", disable: true },
      ],
      selectedMarket: "",
      selectedKPI: "",
      activeTab: "Digital Media",
      activeFrom: 1,
      orderSubCategory: [],
      selectedConnectors: [],
      categorizedSelectedConnectors: [],
      stepName: ["Platform", "DataSource", "KPI"],
      tabs: [
        {
          category: "Digital Media",
          tabContent: [
            {
              title: "Digital Media",
              connectors: [
                { name: "Google Ads", image: "google", role: "DM Google Ads" },
                { name: "Facebook", image: "facebook", role: "DM Facebook" },
                { name: "Instagram", image: "instagram", role: "DM Instagram" },
                { name: "Twitter", image: "twitter", role: "DM Twitter" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },
                { name: "LinkedIn", image: "linkedin", role: "DM LinkedIn" },
                { name: "DV360", image: "dv360", role: "DM DV360" },                
              ],
            },
            {
              title: "Analytics",
              connectors: [
                {
                  name: "Google Analytics",
                  image: "Google_Analytics",
                  role: "Analytics Google Analytics",
                },
                {
                  name: "Adobe Analytics",
                  image: "adobe-anal",
                  role: "Analytics Adobe Analytics",
                },
              ],
            },
            {
              title: "App Anaytics",
              connectors: [
                { name: "App Flyer", image: "appsFlyer", role: "AA App Flyer" },
              ],
            },
          ],
        },
        {
          category: "CRMs",
          tabContent: [],
        },
        {
          category: "Sponsorship",
          tabContent: [],
        },
      ],
      tabsResult: this.tabs,
      connectors: [],
    };
  },
  computed: {
    isDisable() {
      if (
        this.selectedMarket !== "Select" &&
        this.selectedKPI !== "Select" &&
        this.selectedMarket !== "" &&
        this.selectedKPI !== ""
      ) {
        return false;
      }
      return true;
    },
    disableConnectors() {
      if (this.activeFrom === 1) {
        if (this.selectedConnectors.length > 0) {
          return false;
        }
      } else if (this.activeFrom === 2) {
        return false;
      }
      return true;
    },
    isStartNewModal() {
        return this.isShow;
    },
  },
  mounted() {    
    this.getConnections();
  },
  methods: {    
    getConnections() {
      this.connectors = [];
      this.tabs.map((post) => {
        post.tabContent.map((content) => {
          content.connectors.map((connect) => {
            this.connectors.push(connect);
          });
        });
      });
    },
    resultQuery() {
      const search = this.searchTerm.toLowerCase().trim()
      if (!search) {
        this.getConnections();
      } else {
        let result = this.connectors.filter(item => item.name.toLowerCase().indexOf(search) > -1);
        this.connectors = result;
      }
    },
    viewAll() {
      this.isShow = true; 
      this.$modal.show("newWelcomePage");
    },
    createPlan() {
        // this.isShow = true; 
        // console.log("create Plan");
        // this.$modal.show("newWelcomePage");
        //   this.$refs.solutionModal.open();
    },
    hideWelcomePage() {
        this.$modal.hide("newWelcomePage");
    },
    showWelcomePage() {
        this.isShow = true; 
        this.$modal.show("newWelcomePage");
    },
    doneProgress(){
      this.$router.push("/solutions/results");
    },
    selectedKPi(e) {
      if (this.selectedKPI != e) {
        this.selectedKPI = e;
        console.log(e);
      }
    },
    selectMarket(e) {
      if (e === this.selectedMarket || e === "Select") {
        return;
      }
      sessionStorage.setItem("kpiMarket", JSON.stringify(e));
      this.selectedMarket = e;
    },
    orderSubCategoryMethod() {
      for (var i = 0; i < this.categorizedSelectedConnectors.length; i++) {
        var tabContent = [];
        for (
          var j = 0;
          j < this.categorizedSelectedConnectors[i].tabContent.length;
          j++
        ) {
          if (
            tabContent.find(
              (subTitle) =>
                subTitle.title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
            ) === undefined
          ) {
            tabContent.push({
              title: this.categorizedSelectedConnectors[i].tabContent[j].title,
              connectors: [
                {
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image: this.categorizedSelectedConnectors[i].tabContent[j]
                    .className,
                },
              ],
            });
          } else {
            for (var k = 0; k < tabContent.length; k++) {
              if (
                tabContent[k].title ===
                this.categorizedSelectedConnectors[i].tabContent[j].title
              ) {
                tabContent[k].connectors.push({
                  name: this.categorizedSelectedConnectors[i].tabContent[j]
                    .connectorName,
                  image: this.categorizedSelectedConnectors[i].tabContent[j]
                    .className,
                });
              }
            }
          }
          if (
            j ===
            this.categorizedSelectedConnectors[i].tabContent.length - 1
          ) {
            this.categorizedSelectedConnectors[i].tabContent = tabContent;
          }
        }
        if (i === this.categorizedSelectedConnectors.length - 1) {
          this.activeTab = this.categorizedSelectedConnectors[0].category;
        }
      }
    },
    nextToSource() {
      if (this.activeFrom === 2) {
        this.activeFrom = 3;

        return;
      }
      this.activeFrom = 2;
      this.categorizedSelectedConnectors = [];
      for (var i = 0; i < this.selectedConnectors.length; i++) {
        if (
          this.categorizedSelectedConnectors.find(
            (obj) => obj.category === this.selectedConnectors[i].category
          ) === undefined
        ) {
          this.categorizedSelectedConnectors.push({
            category: this.selectedConnectors[i].category,
            tabContent: [this.selectedConnectors[i]],
          });
        } else {
          for (var j = 0; j < this.categorizedSelectedConnectors.length; j++) {
            if (
              this.categorizedSelectedConnectors[j].category ===
              this.selectedConnectors[i].category
            ) {
              this.categorizedSelectedConnectors[j].tabContent.push(
                this.selectedConnectors[i]
              );
            }
          }
        }
        if (i === this.selectedConnectors.length - 1) {
          this.orderSubCategoryMethod();
        }
      }
    },
    changeTab(tabName) {
      this.activeTab = tabName;
    },
    addActiveTab() {},
    addPlatform(obj) {
      var platformobj = obj.obj;
      var title = obj.title;
      if (
        this.selectedConnectors.find(
          (connector) => connector.role === platformobj.role
        ) != undefined
      ) {
        for (var i = 0; i < this.selectedConnectors.length; i++) {
          if (this.selectedConnectors[i].role == platformobj.role) {
            this.selectedConnectors.splice(i, 1);
          }
        }
      } else {
        this.selectedConnectors.push({
          title: title,
          category: this.activeTab,
          connectorName: platformobj.name,
          className: platformobj.image,
          role: platformobj.role,
        });
      }      
    },
  },
  created() {},
};
</script>
<style scoped>
.changeColor .disableButton {
  color: #fff !important;
}
.image {
  background-color: #fff;
  height: 48px;
  width: 48px;
  align-items: center;
}
.action {
  color: #000;
}
.container {
  height: 100%;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #fff;
  margin-bottom: 8px;
}
.step-indicator .stepactive::after {
  background: #050505;
}
.tab-panel.showConnectors {
  display: block;
}
.tab-panel {
  display: none;
}
.card {
  display: block;
}
.search-box {
    width: 450px;
    height: 60px;
        font-size: 16px;
}
.search-button {
    width: 50px;
    background-color: #050505;
}
/deep/.welcome--modal .vm--modal {
    width: 75% !important;
}
/deep/ .modal-connectors .connector{
    height: 80px;
    width: 243px;
    margin: 11px 11px 11px 0;
}
.welcome {
    justify-content: center;
    align-items: flex-start;
    display: flex;
    align-content: flex-start;
}
.modal-body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.modal-body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #e8edf5;
}
span.start-from-fresh.mt-4 {
    width: 59px;
    height: 22px;
    font-size: 18px;
    font-weight: 600;
    float: left;
    cursor: pointer;
}
.btn-effect-intro-modal {
    background-color: #050505;
    color: #f3f3f3;
    border: none;
    text-align: left;
    font-size: 16px;
    padding-left: 20px;
    width: 175px !important;
    height: 60px !important;
}
.img-section{
  padding:3rem;
}
.modal-body{
  overflow-y: scroll;
  width: 525px;
  height: 250px;
  padding: 0;
}
.input-group {
  margin-bottom: 15px;
}
span.float-right.view-all-link {
    font-size: 16px;
    font-family: ProximaNovaRegular;
    cursor: pointer;
}
.Welcome-to-Data-Poem{
    font-size: 32px;
}
::placeholder { 
  color: #9aafd4;
  opacity: 1; 
}
input#form1 {
    background-color: #eff5ff;
}
.card-header{
    font-size: 20px;
}
@media screen and (max-width: 320px){
  .welcome--modal .vm--modal {
      width: 85% !important;
  } 
}
@media screen and (max-width: 640px){
  .welcome--modal .vm--modal {
      width: 85% !important;
  }
  img.img.img-responsive {
      margin: auto;
  }
  .Welcome-to-Data-Poem {
      text-align: center;
  }
  .search-box {
      width: 100%;
      height: 60px;
      font-size: 16px;
      padding-left: 15px;
      margin-left: 35px;
  }
  .w-100.modal-scroll {
      height: 700px;
      overflow-y: scroll;
  }
  .col-sm-6.col-md-3.offset-md-3.text-right.d-flex.justify-content-end {
      width: 40%;
  }
  .cancel-btn-section {
      width: 50%;
  }
}
@media screen and (max-width: 768px){
  p.Welcome-to-Data-Poem{
      text-align: center;
  }
  .kpi-box{
    height: 800px;
  }
  .search-box {
      width: 100%;
      height: 60px;
      font-size: 16px;
      padding-left: 15px;
      margin-left: 35px;
  }
  .modal-body{
    overflow-y: scroll;
    width: 100%;
    height: 550px;
    padding: 0 5rem;
  }
  .w-100.modal-scroll {
      height: 700px;
      overflow-y: scroll;
  }  
  /deep/.modal-connectors .connector {
      width: 215px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width:768px){
  .kpi-box{
    height: 300px;
  }
  .Welcome-to-Data-Poem {
      text-align: center;
  }
  .input-group {
    margin-left: 13rem;
  }
  /deep/.modal-body{
    overflow-y: scroll !important;
    width: 525px;
    height: 250px;
    padding: 0;
    margin-left: 8rem;
  }
}

</style>
